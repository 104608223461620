.faq_h1 {
    font-weight: 800;
    font-size: 8rem;
    color: #fff;
    padding: 20px;
}


.faq_title {
    font-size: 2rem;    
    font-weight: 800;
}

.faq_answer {
    font-size: 1.7rem;
}

.faq_container {
    padding: 20px;
}

.faq_panel {
    background: transparent !important;
    box-shadow: none !important;
    border: 1.6px solid #ffffff33 !important;
    border-radius: 5px !important;
    margin: 20px 0;
}

.faq_panel:before {
    background: none !important;
}

.faq_bg {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #323974, #1a1f4a);
    // background: linear-gradient(to right, rgb(128, 214, 200), #68D7C4);
}

.faq_icon {
    color: #fff;
}

.faq_text {
    color: #fff;
    // background:  linear-gradient(to right, #8e2de2, #4a00e0);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}

.faq_button {
    font-weight: 800 !important;
    font-size: 1.6rem;
    color: #323974 !important;
    background: #fff !important;
    box-shadow: 0px 3px 20px 0px #000530 !important;
}

.faq_button:hover {
    box-shadow: 0px 6px 20px 0px #000530 !important;
}