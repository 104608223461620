// @import url('https://fonts.googleapis.com/css?family=Poppins:400,800');

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
    url(../../../public/fonts/ProductSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Product Sans";
  font-weight: 800;
  src: local("Product Sans"),
    url(../../../public/fonts/ProductSans-Bold.ttf) format("truetype");
}

html {
  font-size: 62.5%;
  height: 100%;
}

a:hover {
  color: none;
}

text {
  font-size: 13px;
}

.cc-window {
  font-family: "Product Sans", sans-serif !important;
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  color: #fff;
  box-shadow: 0px 9px 20px 6px #fe6f849e;
  border-radius: 8px;
}

@media (max-width: 480px) {
  .cc-window {
    border-radius: 0px;
  }
}

.cc-link,
.cc-link:visited {
  color: #fff !important;
}

.cc-btn {
  background: #fff;
  color: #fe6b8b;
  transition: all 1s ease;
  box-shadow: 0px 5px 20px 2px #ffffff7a;
  border: none !important;
  border-radius: 50px;
}

.cc-btn:hover {
  color: #fff;
  background: linear-gradient(
    87deg,
    #fe6b8b,
    #ff8e53,
    #f8b08a,
    #fe6b8b,
    #ff8e53
  );
  background-size: 1000% 1000%;
  animation: ccbtnAnim 3s ease infinite;
}

.para {
  padding-top: 20px;
}

.privacy {
  padding: 20px 10px;
}

body {
  font-family: "Product Sans", sans-serif;
  font-size: $m-size;
  margin: 0;
  min-height: 100vh;
  overflow: visible !important;
  padding-right: 0 !important;
}

h1 {
  font-size: 2.2rem;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.home_intro_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin: 80px 0;
}

.beta {
  font-size: 1.3rem;
}

.dashboard-no-polls {
  display: flex;
  height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-no-polls img {
  height: auto;
  margin: 30px 0;
  width: 400px;
}

.dashboard-no-polls p {
  font-size: 3rem;
}

.is-active {
  font-weight: bold;
  color: red;
}

.fade-enter {
  opacity: 0;
  z-index: 99;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}

.fade_page-enter {
  opacity: 0;
  z-index: 99;
}

.fade_page-appear-active,
.fade_page-enter-active {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.fade_page-exit {
  opacity: 1;
}

.fade_page-exit-active {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

a.welovepewds {
  color: white;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

a.welovepewds:hover {
  color: #a4022b !important;
}

@media only screen and (max-width: 1024px) {
  // .container {
  //     margin: 0 128px;
  // }
  .column.is-6 {
    width: 100%;
  }
}

@keyframes ccbtnAnim {
  0% {
    background-position: 0% 44%;
  }
  50% {
    background-position: 100% 57%;
  }
  100% {
    background-position: 0% 44%;
  }
}
