.about__title {
    color: #000;
    font-weight: 800;
    font-size: 7rem;
}
.about__subtitle {
    color: rgb(83, 83, 83);
    font-weight: 800;
    font-size: 2rem;
}

.about__intro {
    padding-top: 24px;
    font-size: 1.9rem;
    font-color: rgb(83, 83, 83);
}

.about__intro--img {
}

.about__titleContainer {
    padding-top: 6rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about__teamCotainer {
    padding-top: 2.4rem;
    height: 100vh;
}

.about__team__title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__columns {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__card__head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about__card__name {
    font-family: 'Product Sans', sans-serif;
    font-weight: 800;
    font-color: #000;
}

.about__card__sub {
    font-family: 'Product Sans', sans-serif;
    font-color: rgb(83, 83, 83);
}

.about__card__desc {
    font-family: 'Product Sans', sans-serif;
    padding-top: 20px;
    text-align: center;
}

.about__actions {
    display: flex;
    justify-content: center;
}

.about__icon {
    padding: 2px !important;
}

.about__twitter, .about__twitter:hover {
    color: #1da1f2
}
.about__facebook, .about__facebook:hover {
    color: #3b5998
}
.about__insta, .about__insta:hover {
    color: #e1306c
}

.about__github, .about__github:hover {
    color: #333
}
.about__dribbble, .about__dribbble:hover {
    color: #ea4c89
}
.about__spotify, .about__spotify:hover {
    color: #1db954
}
.about__gplus, .about__gplus:hover {
    color: #dd4b39
}
.about__snap, .about__snap:hover {
    color: #fffc00
}
.about__steam, .about__steam:hover {
    color: #000000
}


// Responsive Queries

@media (max-width : 480px) {
    .about__titleContainer {
        height: 162vh;
        padding: 0 20px;
    }
    .about__intro--img {
        height: 300;
    }
}
@media (max-width : 380px) {
    .about__titleContainer {
        height: 240vh;
        padding: 0 20px;
    }
}


// Animations

// @keyframes fadeIn {
//     0% {opacity: 0;}
//     100% {opacity: 1;}
// }