.title_animate {
    animation: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards !important;
    opacity: 0;
    z-index: 1;
}

.subtitle_animate {
    text-align: center;
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards !important;
    opacity: 0;
    z-index: 1;
}

.transparent_appbar {
    background: transparent !important;
    box-shadow: none !important;
    position: static !important;
}

.home_btn {
    color: #00c6ff !important;
    background: #fff !important;
    box-shadow: 0px 3px 20px 0px #005f79c2 !important;
    text-align: center;
    margin-top: 16px !important;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 1;
}

.home_btn:hover {
    box-shadow: 0px 5px 20px 0px #005f79c2 !important;
}

.arrow_animate {
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 1;
}

.arrow {
    text-align: center;
    color: white;
    margin: 1% 0;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.gradientBG {
    background: #00c6ff; 
    background: -webkit-linear-gradient(to right, #0072ff, #00c6ff); 
    background: linear-gradient(to right, #0072ff, #00c6ff); 
}

.appBarContainer {
    z-index: 2;
}

.circle_medium {
        border-radius: 50%;
        width: 128px;
        opacity: 0;
        height: 128px;
        animation: animateMedium;
        animation-fill-mode: forwards;
        box-shadow: 0px 6px 20px 5px rgba(252, 44, 11, 0.5);
        animation-duration: 2s;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        background: #ee0979; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #ee0979, #ff6a00); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ee0979, #ff6a00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */        position: absolute;
        top: 637px;
        left: 305px;
}
.circle_large {
        
        border-radius: 50%;
        width: 30rem;
        opacity: 0;
        height: 30rem;
        animation: animateLarge;
        animation-fill-mode: forwards;
        box-shadow: 0px 6px 20px 5px rgba(252, 44, 11, 0.5);
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        background: #ee0979; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #ee0979, #ff6a00); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ee0979, #ff6a00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */        position: absolute;
        top: 237px;
        left: 57px;
}
.circle_small {
        
        border-radius: 50%;
        width: 68px;
        opacity: 0;
        height: 68px;
        animation: animateSmall;
        animation-fill-mode: forwards;
        box-shadow: 0px 6px 20px 5px rgba(252, 44, 11, 0.5);
        animation-duration: 2s;
        animation-delay: 1s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        background: #ee0979; /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #ee0979, #ff6a00); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ee0979, #ff6a00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */        position: absolute;
        top: 837px;
        left: 161px;
}

@keyframes bounce {

    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

@keyframes fadeIn {
    0% {opacity: 0; transform: skewY(5deg) scale(1.3, 1.3) translateY(30px);}
    100% {opacity: 1; transform: skewY(0) scale(1, 1) translateY(0);}
}

@keyframes animateLarge {
    0% {opacity: 0; top: 237px;}
    100% {opacity: 1; top: 37px} 
}
@keyframes animateSmall {
    0% {opacity: 0; top: 837px;}
    100% {opacity: 1; top: 390px;} 
}
@keyframes animateMedium {
    0% {opacity: 0; top: 637px;}
    100% {opacity: 1; top: 312px;} 
}