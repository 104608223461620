.donate_main {
    // background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
    background: linear-gradient(to right, #141e30, #243b55);
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.donate_grid {
    width: 75%;
    padding-left: 20px !important;
}

.donate_patron_button {
    background-color: #fff !important;
    color: #052f48 !important;
    margin-top: 25px !important;
    box-shadow: 0 3px 20px 0px #ffffffcf !important;
}

.donate_patron_button:hover {
    box-shadow: 0 8px 20px 0px #ffffffcf !important;

}

.donate_patron_button_img {
    height: 30px;
    margin-right: 18px;
}

.donate_h1 {
    font-size: 8.5rem;
    font-weight: 800;
    color: #fff;
    padding-top: 25px;
    padding-left: 20px;
}

.donate_h3 {
    font-size: 6rem;
    font-weight: 800;
    color: #fff;
    padding-top: 25px;
    padding-left: 20px;
}

.donate_h4 {
    font-size: 4rem;
    font-weight: 800;
    color: #fff;
    padding-top: 25px;
    padding-left: 20px;
}

.donate_text_center {
    text-align: center;
}

.donate_patrons{
    display: flex;
    justify-content: center;
}

.donate_patron_name, .donate_patron_name:hover {
    color: #fff;
}

.donate_patron_tester {
    padding: 0 15px;
}

.donate_patron_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.donate_patron_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
}

.tester-hover:hover {
    cursor: pointer;
}
@media(max-width: 768px) {
    .donate_patron_container {
        margin: 20px;
    }
}

@media(max-width: 500px) {
    .donate_patron_container {
        margin: 10px;
    }
}

.donate_subtitle {
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    padding-left: 20px;
}