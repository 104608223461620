.mainPollContainer {
    margin-top: 60px;
    margin-bottom: 28vh;
    padding: 0 1.6rem;
}

.pollCard {
    padding: 30px 0px;
}

.poll_input {
    font-size: 2rem;
    font-weight: 900;
}

.poll-h2 {
    font-weight: 900;
    font-size: 3rem;
}