.mainVote {
    height: 100%;
    color: #fff;
    padding-bottom: 100px;
}

.btnContainer {
    width: 200px;
}

.voteTitle {
    font-weight: 900;
    font-size: 5rem; 
    padding-top: 30px;
    padding-left: 30px;
}



.voteID {
    opacity: 0.6;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.6s cubic-bezier(0.645, 0.045, 0.355, 1.000) infinite alternate both;
	        animation: scale-up-center 0.6s cubic-bezier(0.645, 0.045, 0.355, 1.000) infinite alternate both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

.voteError {
    fill: #b00020 !important;
    width: 6rem !important;
    height: 6rem !important;
}

.voteSuccess {
    fill: #008b00 !important;
    width: 6rem !important;
    height: 6rem !important;
}

.voteWarning {
    fill: #ffde03 !important;
    width: 6rem !important;
    height: 6rem !important;
}

.vColumn {
    width: max-content !important;
}

@media (max-width: 767px) {
    .vColumn {
        font-size: 1rem;
    }
}

.voteCard {
    margin: 20px;
    padding: 60px;
    color:black;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.voteSubtitle {
    font-weight: 600;
    font-size: 3rem;
}

.voteOption {
    font-weight: 400;
    font-size: 2rem;
}