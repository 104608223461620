.mainPollPage {
    height: 100%;
    color: #fff;
    padding-bottom: 100px;
    // background: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
}

.pollSection {
    background: white;
    height: 100px;
    border-radius: 5px;
    position: relative;
    margin: 10px 0px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
  
.pollSection:hover {
    // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 3px 28px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0, 0, 0, 0.22);
}

.pollColumn {
    display: flex;
    align-items: center;
    font-weight: 900;
}

.pollColumns {
    color: #000;
    margin: 0;
    height: 100%;
}

.PollLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.pollValue {
    position: absolute;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 2px rgba(252, 33, 11, 0.72);
    background: linear-gradient(to right, #f9d423 0%, #ff4e50 100%);
    height: 100%;
    width: 0%;
    background: linear-gradient(270deg, #ff4e50, #f9d423);
    background-size: 400% 400%;
    animation: gradientChangePoll 7s ease infinite;
    top: 0;
    opacity: 0.7;
}

.pollResult {
   font-weight: 900;
   font-size: 10rem; 
   padding-top: 70px;
   padding-left: 18px;
}

.pollTitle {
    font-weight: 600;
    font-size: 5rem; 
    padding-top: 30px;
    padding-left: 18px;
}

@keyframes gradientChangePoll { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@media (max-width: 767px) {
    .pollResult {
        font-weight: 900;
        font-size: 8rem; 
    }
     
     .pollTitle {
         font-weight: 600;
         font-size: 3rem; 
    }
}